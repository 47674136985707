<template>
    <div class="orderTable">
        <div class="statistics-item">
            <span class="statistics-text">总交易笔数</span>
            <div class="statistics-number">
                <span class="number">{{ agentOrderData.number > 0 ? agentOrderData.number : 0 }}</span>笔
            </div>
        </div>
        <div class="statistics-item">
            <span class="statistics-text">总交易金额</span>
            <div class="statistics-number">
                <span class="number">{{ agentOrderData.transaction_amount ? agentOrderData.transaction_amount :
                    '0.00'
                }}</span>元
            </div>
        </div>
        <div class="statistics-item">
            <span class="statistics-text">总手续费</span>
            <div class="statistics-number">
                <span class="number">{{ agentOrderData.all_discount_amount ? agentOrderData.all_discount_amount :
                    '0.00'
                }}</span>元
            </div>
        </div>
        <div class="statistics-item">
            <span class="statistics-text">总支付金额</span>
            <div class="statistics-number">
                <span class="number">{{ agentOrderData.all_amount ? agentOrderData.all_amount :
                    '0.00'
                }}</span>元
            </div>
        </div>
        <div class="statistics-item">
            <span class="statistics-text">总优惠金额</span>
            <div class="statistics-number">
                <span class="number">{{ agentOrderData.all_fee_amount ? parseFloat(agentOrderData.all_fee_amount).toFixed(2)
                    :
                    '0.00'
                }}</span>元
            </div>
        </div>
    </div>
    <div class="searchForm">
        <el-form v-model="searchFrom">
            <el-row :gutter="30">
                <el-col :span="6">
                    <!-- <el-form-item label="代理商名称：">
                        <el-input v-model="searchFrom.name"></el-input>
                    </el-form-item> -->

                    <el-form-item label="代理商名称：">
                            <el-select v-model="searchFrom.name" filterable placeholder="请选择代理商" clearable>
                                <el-option v-for="item in childAgent" :key="item" :label="item.name" :value="item.name" />
                            </el-select>
                    </el-form-item>

                </el-col>
                <el-col :span="8">
                    <el-form-item label="时间：">
                        <el-date-picker v-model="searchFrom.date" value-format="YYYY-MM-DD" type="daterange" align="right" unlink-panels
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-button type="primary" @click="search">
                        <el-icon style="color:#fff">
                            <Search />
                        </el-icon>
                        搜索
                    </el-button>

                </el-col>
            </el-row>
        </el-form>
    </div>
    <div class="table">
        <c-table ref="tableRef" :tableDataUrl="tableDataUrl" :border="true" :isLoadData="false">
            <el-table-column type="index" width="55" label="序号" :align="'center'" />
            <el-table-column label="代理商名称" prop="agent_name"></el-table-column>
            <el-table-column label="总交易金额" prop="transaction_amount"></el-table-column>
            <el-table-column label="交易笔数" prop="number"></el-table-column>
            <el-table-column label="总支付金额" prop="all_amount"></el-table-column>
            <el-table-column label="优惠金额" prop="all_fee_amount"></el-table-column>
            <el-table-column label="应收下级分润" prop="receivable_profit"></el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <el-button type="primary" @click="exportFile(scope.row)">
                        导出
                    </el-button>
                </template>
            </el-table-column>
        </c-table>
    </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted, nextTick } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { timeFormat,getTodayStartEnd} from '@/func/time'
import cTable from '@/components/CustomTable'

const { proxy } = getCurrentInstance()
const tableRef = ref(null)
const tableDataUrl = proxy.$api.agent.profit.list
const agentOrderData = ref({})
const searchFrom = ref({
    date: [getTodayStartEnd()['0'],getTodayStartEnd()['1']]
})

onMounted(() => {
    console.log('加载')
    // getStatistics(searchFrom.value)
    // getAgentList(searchFrom.value)
    search()

})

//获取分润汇总
const getStatistics = (params)=>{
    proxy.$api.agent.profit.subordinateAgentsData(params).then(r => {
        if (r.status == 200) {
            agentOrderData.value = r.data
        }
    })
}

//获取代理商名单
const childAgent = ref()
/*const getAgentList = ()=>{
    proxy.$api.agent.agentList().then(r => {
        if (r.status == 200) {
            childAgent.value = r.data
        }
    })
}*/

const search = () => {
    nextTick(() => {
        if (searchFrom.value.date) {
            searchFrom.value['time_start'] = searchFrom.value.date[0] +" " +"00:00:00"
            searchFrom.value['time_end'] = searchFrom.value.date[1] +" " +"23:59:59"
        } else {
            delete searchFrom.value['time_end'];
            delete searchFrom.value['time_start'];
        }
        tableRef.value.setCustomLoad(true)
        tableRef.value.search(searchFrom.value)
        getStatistics(searchFrom.value)
    })
}

const exportFile = (row) => {
    if (searchFrom.value.date) {
        searchFrom.value['time_start'] = timeFormat(searchFrom.value.date[0], 'datetime')
        searchFrom.value['time_end'] = timeFormat(searchFrom.value.date[1], 'datetime')
    }else {
        delete searchFrom.value['time_end'];
        delete searchFrom.value['time_start'];
    }
    searchFrom.value['agent_id'] = row.agent_id
    const filename = '下级代理商交易统计数据-' + timeFormat('', 'string')

    proxy.$api.agent.profit.export(searchFrom.value, filename)
}
</script>

<style lang="less" scoped>
.orderTable {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: rgb(210, 220, 250);
    border-radius: 5px;

    .statistics-item {
        flex: 1;
        text-align: center;
        margin: 10px;

        .statistics-number {
            text-align: center;

            .number {
                color: red;
                margin-right: 10px;
            }
        }
    }

    .statistics-item:not(:last-child) {
        border-right: 1px solid #fff;
    }
}


.searchForm {
    margin-top: 20px;
    margin-bottom: 35px;
}</style>